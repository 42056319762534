.Default-button {
  border: none;
  color: black;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.5em;
  margin: 4px 2px;
  cursor: pointer;
}

.Default-button:active {
  background-color: #d0d0d0;
}

.Default-button:disabled {
  opacity: .3;
  cursor: default;
}

.Default-button-blue {
  color: white;
  background-color: #008CBA;
}

.Default-button-neutral {
  color: black;
  background-color: white;
}

.Default-button-green {
  color: white;
  background-color: #28a745;
}
