.Pager {
  min-width: 700px;
  padding: 40px;
  background-color: rgba(255, 0, 0, 0.1);
}

.Pager-container  {
  padding: 10px;
  background-color: white;
}
