.LoginForm {
  min-width: 500px;
  padding: 60px;
  background-color: rgba(40, 167, 69, 0.7);
}

.LoginForm-container  {
  display:flex;
  flex-direction: column;
  padding: 60px;
  background-color: white;
}
